import React, { useContext, useState, useMemo } from "react";
import { Row, Col, ListGroup, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPen, FaSearch, FaAngleDown, FaAngleUp } from "react-icons/fa";
import moment from "moment";
import { GAConfigContext } from "../../contexts/gaConfigContext";

const TagBadge = ({ tagInstance, showDelete = false, onDelete = null }) =>
{
	const { GA_CONFIG } = useContext(GAConfigContext);

	const [isSaving, setIsSaving] = useState(false);

	const isSuperAdmin = useMemo(() => !! GA_CONFIG.user.adminOptions[GA_CONFIG.adminOpts.ARE_EXTRA_PRIV], [GA_CONFIG]);

	const removeTag = async() =>
	{
		setIsSaving(true);

		try
		{
			await onDelete(tagInstance.id);
		}
		catch (err)
		{
			alert(err.toString());
			setIsSaving(false);
			return;
		}

		setIsSaving(false);
	};

	const renderDeleteButton = () =>
	{
		if(! showDelete)
			return null;

		const cannotDelete = (tagInstance.tag.readonly === 1) || (tagInstance.tag.protected === 1 && (! isSuperAdmin));

		return (<Col md={"auto"}>
			<Button variant="danger" size="sm" disabled={cannotDelete || isSaving} onClick={removeTag}>Remove</Button>
		</Col>);
	};

	const renderFormattedValue = () =>
	{
		if(tagInstance.tag.options?.type === "url")
		{
			return <a href={tagInstance.value} target="_BLANK" rel="noreferrer">{tagInstance.value}</a>;
		}
		else if(tagInstance.tag.options?.type === "boolean")
		{
			return <>{parseInt(tagInstance.value) === 1 ? "Yes" : "No"}</>;
		}
		else if(tagInstance.tag.options?.type === "json")
		{
			try
			{

				const [isCollapsed, setIsCollapsed] = useState(true);
				const value = JSON.parse(tagInstance.value);
				const keys = Object.keys(value);

				const handleToggleCollapse = () =>
				{
					setIsCollapsed(!isCollapsed);
				};

				return (
					<>
						{keys.slice(0, isCollapsed ? 3 : keys.length).map(k => (
							<Row key={k}>
								<strong>{k}</strong>: {value[k]}
							</Row>
						))}
						{keys.length > 3 && (
							<Row>
								<button onClick={handleToggleCollapse} className="btn btn-link p-0">
									{isCollapsed ?
										<>
											<FaAngleDown style={{ marginRight: "6", marginBottom: "2px" }} />
											Show More
										</>
										:
										<>
											<FaAngleUp style={{ marginRight: "6", marginBottom: "2px" }} />
											Show Less
										</>
									}
								</button>
							</Row>
						)}
					</>
				);
			}
			catch (e)
			{
				return "Invalid value, please send an email to bugs@getagent.co.uk";
			}
		}
		else
		{
			return <>{tagInstance.value}</>;
		}
	};

	const renderCreatedDetails = () =>
	{
		if(showDelete)
			return null;

		return <Badge variant="primary" style={{whiteSpace: "normal", display: "block"}}><FaPen /> {tagInstance.addedBy?.name || "Auto"}, {moment(tagInstance.created_at).format("ddd Do MMM YYYY, HH:mm")}</Badge>;
	};

	const hideSearch = tagInstance.tag.options.type === "json";

	return (<ListGroup.Item>
		<Row className="align-items-center">
			<Col md={3}>
				<Link to={"/tags/search?tagId=" + tagInstance.tag_id + "&value=" + encodeURIComponent(tagInstance.value)}>
					{!hideSearch ?
						<FaSearch style={{ marginRight: "6", marginBottom: "2px" }} />
						: null}
				</Link>
				<strong>
					{tagInstance.tag.name}:
				</strong>
			</Col>
			<Col md={6}>
				{renderFormattedValue()}
			</Col>
			<Col md={3}>{renderDeleteButton()}{renderCreatedDetails()}</Col>
		</Row>
	</ListGroup.Item>);

};


export default TagBadge;